import { useQuery } from '@apollo/client';

import { GET_CATEGORIES, GetCategoriesResponse } from 'data/graphql/queries';

import Logger from 'lib/utils/Logger';

const useGetCategories = () => {
  const { data, error, loading } =
    useQuery<GetCategoriesResponse>(GET_CATEGORIES);

  if (error) {
    Logger.error('Something went wrong running GET_CATEGORIES query', error);
  }

  return {
    categories: data?.categories,
    error,
    loading,
  };
};

export default useGetCategories;
