import { useQuery } from '@apollo/client';
import { isBefore, isAfter, parseISO } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

import { useGetCategories } from 'lib/hooks/categories';
import Logger from 'lib/utils/Logger';

import { GET_PROMO_CODE_QUERY } from 'components/MarkdownPromoContainer/MarkdownPromoContainer.gql';

import { ApiProductVariant } from 'data/graphql/types';
import {
  CategoryV2,
  GetPromoCodeQuery,
  PromotionDetailResponse,
  PromotionEligibilityType,
} from 'types/generated/api';

export type UsePromoEligibleResult = {
  code?: string;
  details?: string;
  discount: number;
  doesPromoApplyToDiscountedItems: boolean;
  eligibleSkus: string[];
  error?: Error;
  id?: string;
  isEligibleForPromo: boolean;
  loading: boolean;
  minSubtotal?: number;
};

export type UsePromoEligibleArgs = Array<{
  productCategoryHierarchy: string[];
  productDetails: Pick<
    ApiProductVariant,
    | 'compareAtPrice'
    | 'eligiblePromotions'
    | 'isDiscountable'
    | 'isMarketplace'
    | 'isProductBundle'
    | 'offerDetails'
    | 'price'
    | 'sku'
  >;
}>;

export type MarkdownVariantDetails = Pick<
  ApiProductVariant,
  | 'compareAtPrice'
  | 'eligiblePromotions'
  | 'isDiscountable'
  | 'isMarketplace'
  | 'isProductBundle'
  | 'offerDetails'
  | 'price'
  | 'sku'
>;

export const usePromoEligible = (
  products: UsePromoEligibleArgs
): UsePromoEligibleResult => {
  let isEligibleForPromo = false;
  let discount = 0;
  const { data, error, loading } =
    useQuery<GetPromoCodeQuery>(GET_PROMO_CODE_QUERY);

  if (error) {
    Logger.error('Something went wrong running GET_PROMO_CODE_QUERY', error);
  }

  const { categories: allCategories, loading: categoriesLoading } =
    useGetCategories();

  const eligibleSkus: string[] = [];

  if (data?.promoCode && allCategories) {
    const promoDetails = data.promoCode;

    products.forEach(product => {
      const { discountAmount, isEligible } = getValidPromoForVariant(
        product.productDetails,
        product.productCategoryHierarchy,
        allCategories,
        promoDetails
      );

      isEligibleForPromo = isEligibleForPromo || isEligible;
      discount = Math.max(discountAmount, discount);

      if (isEligible) {
        eligibleSkus.push(product.productDetails.sku);
      }
    });
  }

  return {
    code: data?.promoCode?.code,
    details: data?.promoCode?.details,
    discount,
    doesPromoApplyToDiscountedItems:
      data?.promoCode?.appliesToDiscountedItems ?? false,
    eligibleSkus,
    error,
    id: data?.promoCode?.id,
    isEligibleForPromo,
    loading: loading || categoriesLoading,
    minSubtotal: data?.promoCode?.minSubtotal ?? undefined,
  };
};

type GetValidPromoForVariantResponse = {
  discountAmount: number;
  isEligible: boolean;
};

export const getValidPromoForVariant = (
  productVariant: MarkdownVariantDetails,
  productCategoryHierarchies: string[],
  allCategories: CategoryV2[],
  promoCode: NonNullable<GetPromoCodeQuery['promoCode']>
): GetValidPromoForVariantResponse => {
  let isEligible = false;
  const discountAmounts: number[] = [];

  if (isPromoCodeCurrent(promoCode)) {
    promoCode?.discounts?.forEach(discount => {
      if (!discount?.eligibility) {
        return;
      }

      for (const eligibilityItem of discount?.eligibility) {
        const eligibilityType = eligibilityItem?.type;
        const eligibilityValue = eligibilityItem?.value;

        const isSkuMatch =
          eligibilityType === PromotionEligibilityType.SKU &&
          productVariant?.sku === eligibilityValue;

        const isCategoryEligible =
          eligibilityType === PromotionEligibilityType.CATEGORY &&
          isPromoCodeApplicable(promoCode, productVariant) &&
          isCategoryPromoEligible(
            productCategoryHierarchies,
            allCategories,
            eligibilityValue
          );

        if (isSkuMatch || isCategoryEligible) {
          isEligible = true;
          discountAmounts.push(discount.discount);
        }
      }
    });
  }

  if (isEmpty(discountAmounts)) {
    discountAmounts.push(0); // to avoid math.max returning `infinity` on empty array
  }

  // pick the lowest discount of all the detected values, but not less than 0
  const discountAmount = Math.max(0, Math.min(...discountAmounts));

  return { discountAmount, isEligible };
};

export const isCategoryPromoEligible = (
  productCategoryHierarchies: string[],
  allCategories: CategoryV2[],
  promoCategorySlug: string
): boolean => {
  if (
    isEmpty(productCategoryHierarchies) ||
    isEmpty(allCategories) ||
    isEmpty(promoCategorySlug)
  ) {
    return false;
  }

  const promoCategory = allCategories.find(
    category => category.slug === promoCategorySlug
  );

  if (!promoCategory) {
    return false;
  }

  let isEligible = false;
  for (const productCategory of productCategoryHierarchies) {
    if (productCategory) {
      isEligible =
        productCategory.indexOf(promoCategory.algoliaHierarchy) === 0;
    }

    if (isEligible) {
      break;
    }
  }

  return isEligible;
};

export const isCurrentDateWithinPromoRange = (
  startDate?: string | null,
  endDate?: string | null
): boolean => {
  try {
    if (!startDate || !endDate) {
      Logger.error(
        `Promo code is missing range information. startDate: ${startDate}, endDate: ${endDate}`
      );
      return false;
    }

    const currentDate = new Date().toISOString();
    return (
      isBefore(parseISO(currentDate), parseISO(endDate)) &&
      isAfter(parseISO(currentDate), parseISO(startDate))
    );
  } catch (error) {
    Logger.error(
      'Something went wrong running shouldDisplayTemporaryMarkdown',
      error
    );
    return false;
  }
};

export const isPromoCodeApplicable = (
  promoCode: Pick<
    PromotionDetailResponse,
    | 'appliesToBundles'
    | 'appliesToDiscountedItems'
    | 'endDate'
    | 'id'
    | 'startDate'
  >,
  productVariant: MarkdownVariantDetails
): boolean => {
  // Promo Messaging Eligibility: Products variants are eligible based on the following rules:
  // 1. There must be an active promotion.
  // 2. Wholesale products must be discountable.
  // 3. Marketplace products must be eligible for the active promotion.
  // 4. Marked down products are not eligible, unless the promotion applies
  //    to discounted items.
  // 5. Product bundles are not eligible unless promotion applies to bundled items.

  // check that promo code is still current
  if (!isPromoCodeCurrent(promoCode)) {
    return false;
  }

  // Ensure wholesale and marketplace products are discountable
  if (
    (productVariant.isMarketplace &&
      !productVariant.eligiblePromotions?.find(
        promoId => promoId === promoCode.id
      )) ||
    (!productVariant.isMarketplace && !productVariant.isDiscountable)
  ) {
    return false;
  }

  // check if the variant is discounted (markdown item)
  // and if so, whether this promo code applies to discounted items
  if (
    productVariant.price < productVariant.compareAtPrice &&
    !promoCode.appliesToDiscountedItems
  ) {
    return false;
  }

  // check if the variant is a bundle item and whether promo applies to bundles
  if (productVariant?.isProductBundle && !promoCode?.appliesToBundles) {
    return false;
  }

  return true;
};

/*
 * Checks whether the current time is still within the promo code start/end times
 */
export const isPromoCodeCurrent = (
  promoCode: Pick<PromotionDetailResponse, 'endDate' | 'startDate'>
): boolean => {
  return isCurrentDateWithinPromoRange(
    promoCode?.startDate,
    promoCode?.endDate
  );
};

/*
 * Due to graphql Product.categories response only containing labels, this goes
 * through all global categories (which contain more details, like slug and
 * value), and returns those that match the Product's
 */
export const getProductCategories = (
  productCategories: string[][] = [],
  allCategories?: CategoryV2[]
): CategoryV2[] => {
  const matchingCategories: CategoryV2[] = [];

  if (isEmpty(productCategories) || isEmpty(allCategories)) {
    return [];
  }

  productCategories.forEach(productCategory => {
    const matchingCategory = findCategoryByLevels(
      productCategory,
      allCategories || []
    );

    if (matchingCategory) {
      matchingCategories.push(matchingCategory);
    }
  });

  return matchingCategories;
};

export const findCategoryByLevels = (
  productCategory: string[],
  categories: CategoryV2[]
): CategoryV2 | null => {
  try {
    if (isEmpty(categories) || isEmpty(productCategory)) {
      return null;
    }

    return (
      categories.find((category: CategoryV2) => {
        const productCategoryHierarchy = productCategory.join(' > ');
        return productCategoryHierarchy === category.algoliaHierarchy;
      }) || null
    );
  } catch (error) {
    Logger.error(
      'Unexpected Error. Something went wrong inside findCategoryByLevels();',
      error
    );
    return null;
  }
};

export const flattenProductCategoryLevels = (
  productCategories?: Array<{ category: string[] }> | null
): string[][] => {
  if (isEmpty(productCategories) || !productCategories) {
    return [];
  }

  return productCategories?.map(({ category }) => category);
};
